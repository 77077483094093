/* eslint-disable import/prefer-default-export */
export const mainMenu = [
  {
    link: '/payments/',
    title: '使い方',
    isExternal: false,
  },
  {
    link: '/shop/',
    title: '使えるお店',
    isExternal: false,
  },
  {
    link: '/npay/',
    title: '分割あと払い',
    isExternal: false,
  },
  {
    link: '/paidycard/',
    title: 'ペイディカード',
    isExternal: false,
  },
  {
    link: '/campaign/',
    title: 'キャンペーン',
    isExternal: false,
  },
  {
    link: '/column/',
    title: 'お知らせ',
    isExternal: false,
  },
  {
    link: 'https://faq.paidy.com/',
    title: 'よくあるご質問',
    isExternal: true,
  },
  {
    link: '/merchant/',
    title: '事業者の方へ',
    isExternal: false,
    hasIconExternal: true,
  },
];

export const mobileMainMenu = [
  {
    link: '/payments/',
    title: '使い方',
    isExternal: false,
  },
  {
    link: '/shop/',
    title: '使えるお店',
    isExternal: false,
  },
  {
    link: '/npay/',
    title: '分割あと払い',
    isExternal: false,
  },
  {
    link: '/paidycard/',
    title: 'ペイディカード',
    isExternal: false,
  },
  {
    link: '/campaign/',
    title: 'キャンペーン',
    isExternal: false,
  },
  {
    link: '/column/',
    title: 'お知らせ',
    isExternal: false,
  },
  {
    link: 'https://faq.paidy.com/',
    title: 'よくあるご質問',
    isExternal: true,
  },
];

export const merchantMenu = [
  {
    link: '/merchant/',
    title: 'トップ',
  },
  {
    link: '/merchant/industry',
    title: '業種別の使い方',
    subMenu: [
      {
        link: '/merchant/industry/goods/',
        title: '物販',
      },
      {
        link: '/merchant/industry/digital/',
        title: 'デジタルコンテンツ',
      },
      {
        link: '/merchant/industry/travel/',
        title: 'トラベル・アクティビティ',
      },
      {
        link: '/merchant/industry/subscription/',
        title: 'サブスクリプション',
      },
    ],
  },
  {
    link: '/merchant/application/',
    title: '導入の流れ',
  },
  {
    link: '/merchant/column/',
    followLink: '/merchant/column/',
    title: 'お知らせ',
  },
  {
    link: '/merchant/materials/',
    title: '各種資料',
    subMenu: [
      // {
      //   link: '/merchant/materials/white_paper/',
      //   title: 'お役立ち情報',
      // },
      {
        link: '/merchant/materials/sales/',
        title: '資料請求',
      },
      {
        extLink: 'https://paidy.com/docs/jp/',
        title: '開発向けドキュメント',
      },
      {
        link: '/merchant/materials/faq/',
        title: 'よくあるご質問',
      },
    ],
  },
  {
    link: '/merchant/partner/',
    title: 'ペイディパートナー',
  },
];

export const mobileMerchantMenu = [
  {
    link: '/merchant/',
    title: 'トップ',
  },
  {
    extLink: 'https://merchant.paidy.com/login',
    title: 'ログイン',
  },
  {
    link: '/merchant/industry',
    title: '業種別の使い方',
    subMenu: [
      {
        link: '/merchant/industry/goods/',
        title: '物販',
      },
      {
        link: '/merchant/industry/digital/',
        title: 'デジタルコンテンツ',
      },
      {
        link: '/merchant/industry/travel/',
        title: 'トラベル・アクティビティ',
      },
      {
        link: '/merchant/industry/subscription/',
        title: 'サブスクリプション',
      },
    ],
  },
  {
    link: '/merchant/application/',
    title: '導入の流れ',
  },
  {
    link: '/merchant/column/',
    followLink: '/merchant/column/',
    title: 'お知らせ',
  },
  {
    link: '/merchant/materials/',
    title: '各種資料',
    subMenu: [
      // {
      //   link: '/merchant/materials/white_paper/',
      //   title: 'お役立ち情報',
      // },
      {
        link: '/merchant/materials/sales/',
        title: '資料請求',
      },
      {
        extLink: 'https://paidy.com/docs/jp/',
        title: '開発向けドキュメント',
      },
      {
        link: '/merchant/materials/faq/',
        title: 'よくあるご質問',
      },
    ],
  },
  {
    link: '/merchant/partner/',
    title: 'ペイディパートナー',
  },
];

export const tabletMerchantMenu = [].concat(merchantMenu);
tabletMerchantMenu.splice(1, 0, {
  extLink: 'https://my.paidy.com/login/',
  title: 'ログイン',
});
