/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Location } from '@reach/router';
import cx from 'classnames';
import throttle from 'lodash.throttle';

import SEO from '../SEO';
import HeaderMerchant from '../Header/HeaderMerchant';
import Header from '../Header/Header';
import HeaderMobile from '../Header/HeaderMobile';
import HeaderMerchantMobile from '../Header/HeaderMerchantMobile';
import Footer from '../Footer/Footer';
import FooterMobile from '../Footer/FooterMobile';
import './Layout.scss';
import { gtag } from '../../utils';

const scrollTrackThresholds = [25, 50, 75, 100];

const Layout = ({
  isMerchant,
  isMediaCenter,
  noBG,
  hasNoHeader,
  hasNoHeaderNav,
  hasNoFooter,
  isCompanyPage,
  children,
  SEOProps,
  backgroundColor,
}: {
  isMerchant?: boolean,
  isPaymentsPage?: boolean,
  isMediaCenter?: boolean,
  noBG?: boolean,
  hasNoHeader?: boolean,
  hasNoHeaderNav?: boolean,
  hasNoFooter?: boolean,
  isCompanyPage?: boolean,
  children: React$Node,
  SEOProps: Object,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const currentMaxScrollIndex = useRef(0);

  // track scroll percentage
  const onScroll = throttle(() => {
    const viewportH = window.innerHeight;
    const pageH = document.documentElement.scrollHeight;

    const offset = window.scrollY;
    const scrollPct = Math.ceil((offset / (pageH - viewportH)) * 100);

    const lbIndex = scrollTrackThresholds.findLastIndex(
      (thres) => thres - 1 <= scrollPct
    );

    if (currentMaxScrollIndex.current < lbIndex + 1) {
      gtag({ event: 'scroll_depth', ga_depth: scrollTrackThresholds[lbIndex] });
      currentMaxScrollIndex.current = lbIndex + 1;
    }
  }, 200);

  useEffect(() => {
    currentMaxScrollIndex.current = 0;
    // ignore fst 1 sec to track.
    // Due to smooth scrolling when changing route,
    setTimeout(() => {
      window.addEventListener('scroll', onScroll, { passive: true });

      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    }, 1000);
  }, []);

  return (
    <div
      id="paidy_root"
      className={cx({ 'no-bg': noBG })}
      style={{ backgroundColor }}
    >
      <Location>
        {({ location }) => (
          <>
            <SEO
              pathname={location.pathname}
              audience={isMerchant ? 'merchant' : 'consumer'}
              {...SEOProps}
            />
            <div className="pc">
              {(() => {
                if (!hasNoHeader && isMerchant) {
                  return (
                    <HeaderMerchant
                      siteTitle={data.site.siteMetadata.title}
                      pathname={location.pathname}
                    />
                  );
                } else if (!hasNoHeader && !isMerchant) {
                  return (
                    <Header
                      siteTitle={data.site.siteMetadata.title}
                      pathname={location.pathname}
                      alwaysWhite={isMediaCenter}
                    />
                  );
                }

                return <></>;
              })()}
            </div>
            <div className="sp header-sp">
              {(() => {
                if (!hasNoHeader && isMerchant) {
                  return (
                    <HeaderMerchantMobile
                      siteTitle={data.site.siteMetadata.title}
                      pathname={location.pathname}
                      hasNoHeader={hasNoHeader}
                      hasNoHeaderNav={hasNoHeaderNav}
                    />
                  );
                } else if (!hasNoHeader && !isMerchant) {
                  return (
                    <HeaderMobile
                      siteTitle={data.site.siteMetadata.title}
                      pathname={location.pathname}
                      isCompanyPage={isCompanyPage}
                      hasNoHeader={hasNoHeader}
                      hasNoHeaderNav={hasNoHeaderNav}
                      alwaysWhite={isMediaCenter}
                    />
                  );
                }

                return <></>;
              })()}
            </div>

            <main className={cx({ merchant: isMerchant })}>{children}</main>
            <div className="pc">{!hasNoFooter && <Footer />}</div>
            <div className="sp">{!hasNoFooter && <FooterMobile />}</div>
          </>
        )}
      </Location>
    </div>
  );
};

Layout.defaultProps = {
  isMerchant: false,
  isPaymentsPage: false,
  isCompanyPage: false,
  isMediaCenter: false,
  noBG: false,
  hasNoHeader: false,
  hasNoHeaderNav: false,
  hasNoFooter: false,
  children: null,
  SEOProps: {},
};

export default Layout;
