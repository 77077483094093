/* eslint-disable no-nested-ternary */
// @flow
import React, { useState } from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import LinkBox from './LinkBox';
import styles from './HeaderMobile.module.scss';
import { mobileMerchantMenu } from './const';

const HeaderMerchantMobile = ({
  pathname,
  hasNoHeaderNav,
}: {
  pathname: string,
  hasNoHeaderNav: boolean,
}) => {
  const [isHeaderNavActive, setIsHeaderNavActive] = useState(false);
  const [hasWhiteBg, setHasWhiteBg] = React.useState(false);

  const onClick = () => {
    setIsHeaderNavActive(false);
  };

  React.useEffect(() => {
    const updateBgOnScroll = () => {
      setHasWhiteBg(window.scrollY > 0);
    };

    window.addEventListener('scroll', updateBgOnScroll);

    return () => window.removeEventListener('scroll', updateBgOnScroll);
  }, []);

  return (
    <div
      className={cx(
        styles.headerWrapper,
        {
          [styles.hasNoHeaderNav]: hasNoHeaderNav,
        },
        hasWhiteBg && styles.whiteBg
      )}
    >
      <header
        className={cx(styles.header, { [styles.isActive]: isHeaderNavActive })}
      >
        <div className={styles.topBlock}>
          <Link to="/" className={styles.headerLogo} onClick={onClick}>
            <img
              alt="paidy"
              src={require('src/images/logo-color-2.svg')}
              width={80}
              height={22}
            />
          </Link>
          <button
            className={cx(styles.menu, 'a11y')}
            onClick={() => setIsHeaderNavActive(!isHeaderNavActive)}
          >
            Menu
          </button>
          {!hasNoHeaderNav && (
            <Link
              className={cx(styles.btnLogin, {
                [styles.active]: pathname.endsWith('/merchant/contact_us/'),
              })}
              to="/merchant/contact_us/"
              onClick={onClick}
            >
              導入相談
            </Link>
          )}
        </div>
        {isHeaderNavActive && (
          <nav className={styles.mainNav}>
            {mobileMerchantMenu.map((menuItem) =>
              menuItem.subMenu ? (
                <LinkBox
                  to={menuItem.link}
                  text={menuItem.title}
                  key={menuItem.title}
                  activeSubMenu={
                    pathname ? pathname.startsWith(menuItem.link) : false
                  }
                >
                  <nav>
                    {menuItem.subMenu.map((subMenuItem) =>
                      subMenuItem.link ? (
                        <Link
                          to={subMenuItem.link}
                          onClick={onClick}
                          className={cx(
                            pathname.endsWith(subMenuItem.link)
                              ? styles.active
                              : ''
                          )}
                          key={subMenuItem.title}
                        >
                          {subMenuItem.title}
                        </Link>
                      ) : (
                        <OutboundLink
                          key={subMenuItem.extLink}
                          href={subMenuItem.extLink}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {subMenuItem.title}
                        </OutboundLink>
                      )
                    )}
                  </nav>
                </LinkBox>
              ) : menuItem.link ? (
                <Link
                  className={cx({
                    [styles.active]:
                      pathname.endsWith(menuItem.link) ||
                      pathname.startsWith(menuItem.followLink),
                  })}
                  to={menuItem.link}
                  onClick={onClick}
                  key={menuItem.title}
                >
                  {menuItem.title}
                </Link>
              ) : (
                <div className={styles.linkBox} key={menuItem.extLink}>
                  <OutboundLink
                    href={menuItem.extLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {menuItem.title}
                  </OutboundLink>
                </div>
              )
            )}
          </nav>
        )}
      </header>
    </div>
  );
};

HeaderMerchantMobile.defaultProps = {
  siteTitle: ``,
  hasNoHeaderNav: false,
};

export default HeaderMerchantMobile;
