// @flow
import React, { useState } from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';

import styles from './HeaderMobile.module.scss';

const LinkBox = ({
  to,
  text,
  children,
}: {
  to: string,
  text: string,
  children: React$Node,
}) => {
  const [isActive, setIsActive] = useState(false);

  const onToggle = (event) => {
    event.preventDefault();

    setIsActive(!isActive);
  };

  return (
    <div className={cx(styles.linkBox, isActive ? styles.isActive : '')}>
      <button
        className={cx(styles.toggle, 'a11y', isActive ? styles.isActive : '')}
        onClick={onToggle}
      >
        Toggle
      </button>
      <Link to={to} onClick={onToggle}>
        {text}
      </Link>
      {children}
    </div>
  );
};

export default LinkBox;
